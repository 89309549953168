const en = {
    "WarningTips": "Please make sure that after deducting the withdrawal fee, the amount transferred to the wallet address is the same as the order, and the wrong amount will not be credited!",
    "Payment": "Please complete the payment within",
    "Scan": "Scan to pay",
    "OrderNumber": "Order number",
    "OrderSuccess": "The order has been paid successfully",
    "OrderReview": "Order payment pending review",
    "OrderClosedTips": "The order has been closed and your payment has failed",
    "OrderResubmit": "Order is closed, if you have paid in the meantime please close the page or please resubmit the order!",
    "Description": "Description",
    "DescriptionInfo1": ["1.You can only recharge ", " to this address, otherwise the assets cannot be retrieved!"],
    "DescriptionInfo2": "2.Please recharge within the validity period of the order, otherwise it will not be credited!",
    "DescriptionInfo3": "2.Please make sure the address to transfer to, if the address is incorrect, it will not be credited!",
    "CopySuccessfully": "Copy successfully",
    "ReplicationFailed": "Replication failed"
}

const es = {
    "WarningTips": "¡Asegúrese de que después de deducir la tarifa de retiro, el monto transferido a la dirección de la billetera sea el mismo que el pedido, y no se acreditará el monto incorrecto!",
    "Payment": "Por favor complete el pago dentro",
    "Scan": "Escanear para pagar",
    "OrderNumber": "Número de orden",
    "OrderSuccess": "El pedido ha sido pagado con éxito",
    "OrderReview": "Pago del pedido pendiente de revisión",
    "OrderClosedTips": "El pedido ha sido cerrado y su pago ha fallado",
    "OrderResubmit": "El pedido está cerrado, si ha pagado mientras tanto, cierre la página o vuelva a enviar el pedido.",
    "Description": "Descripción",
    "DescriptionInfo1": ["1.Solo puede recargar ", " a esta dirección, de lo contrario, los activos no se pueden recuperar."],
    "DescriptionInfo2": "2.Por favor, recargue dentro del período de validez del pedido, de lo contrario, no se acreditará.",
    "DescriptionInfo3": "2.Por favor, asegúrese de que la dirección a transferir, si la dirección es incorrecta, ¡no se acreditará!",
    "CopySuccessfully": "Copiar con éxito",
    "ReplicationFailed": "Error de replicación"
}

const pt = {
    "WarningTips": "Certifique-se de que, após deduzir a taxa de retirada, o valor transferido para o endereço da carteira seja o mesmo do pedido e o valor errado não será creditado!",
    "Payment": "Por favor, complete o pagamento dentro de",
    "Scan": "Digitalizar para pagar",
    "OrderNumber": "Número do pedido",
    "OrderSuccess": "O pedido foi pago com sucesso",
    "OrderReview": "Pagamento do pedido pendente de revisão",
    "OrderClosedTips": "O pedido foi fechado e seu pagamento falhou",
    "OrderResubmit": "O pedido está fechado, se você pagou nesse meio tempo, feche a página ou reenvie o pedido!",
    "Description": "Descrição",
    "DescriptionInfo1": ["1.Você só pode recarregar ", " para este endereço, caso contrário, os ativos não poderão ser recuperados!"],
    "DescriptionInfo2": "2.Recarregue dentro do prazo de validade do pedido, caso contrário não será creditado!",
    "DescriptionInfo3": "2.Certifique-se de que o endereço para a transferência, se o endereço estiver incorreto, não será creditado!",
    "CopySuccessfully": "Copiar com sucesso",
    "ReplicationFailed": "Falha na replicação"
}

const zh_tw = {
    "WarningTips": "請確保扣除提現手續費後，轉入錢包地址的金額與訂單金額一致，錯誤金額不入賬！",
    "Payment": "請於內完成付款",
    "Scan": "掃碼付款",
    "OrderNumber": "訂單號",
    "OrderSuccess": "訂單已成功支付",
    "OrderReview": "訂單付款待審核",
    "OrderClosedTips": "訂單已關閉，您的付款失敗",
    "OrderResubmit": "訂單已關閉，如果您在此期間已付款，請關閉頁面或重新提交訂單！",
    "Description": "注意",
    "DescriptionInfo1": ["1.您只能向該地址充值", "，否則無法找回資產！"],
    "DescriptionInfo2": "2.請在訂單有效期內充值，否則無法入賬！",
    "DescriptionInfo3": "2.請務必確認要轉賬的地址，地址錯誤將不予入賬！",
    "CopySuccessfully": "複製成功",
    "ReplicationFailed": "複製失敗"
}

const ar = {
    "WarningTips": "يرجى التأكد من أنه بعد خصم رسوم السحب ، فإن المبلغ المحول إلى عنوان المحفظة هو نفس الطلب ، ولن يتم إضافة المبلغ الخطأ!",
    "Payment": "يرجى إكمال الدفع في غضون",
    "Scan": "امسح للدفع",
    "OrderNumber": "رقم الطلب",
    "OrderSuccess": "تم دفع الطلب بنجاح",
    "OrderReview": "طلب الدفع في انتظار المراجعة",
    "OrderClosedTips": "تم إغلاق الطلب وفشل الدفع الخاص بك",
    "OrderResubmit": "الطلب مغلق ، إذا كنت قد دفعت في غضون ذلك ، يرجى إغلاق الصفحة أو الرجاء إعادة إرسال الطلب!",
    "Description": "وصف",
    "DescriptionInfo1": ["1.يمكنك فقط إعادة شحن ", " على هذا العنوان ، وإلا فلا يمكن استرداد الأصول!"],
    "DescriptionInfo2": "2.يرجى إعادة الشحن خلال فترة صلاحية الطلب ، وإلا فلن يتم إيداعه!",
    "DescriptionInfo3": "2.يرجى التأكد من العنوان المراد التحويل إليه ، إذا كان العنوان غير صحيح ، فلن يتم قيده!",
    "CopySuccessfully": "نسخ بنجاح",
    "ReplicationFailed": "فشل النسخ المتماثل"
}

const langList = [
    {
        name: 'English',
        key: 'en',
        value: en
    },
    {
        name: 'Español',
        key: 'es',
        value: es
    },
    {
        name: 'Português',
        key: 'pt',
        value: pt
    },
    {
        name: '繁體中文',
        key: 'zh_tw',
        value: zh_tw
    },
    {
        name: 'عربي',
        key: 'ar',
        value: ar
    }
]

export default langList