export default [{
        'blockName': 'TRON',
        'coinId': 0,
        'coinName': 'TRX',
        'decimal': 6,
        'contractAddress': '',
        'icon': require('@/assets/TRX.png')
    },
    {
        'blockName': 'TRON',
        'coinId': 1,
        'coinName': 'USDT_TRC20',
        'decimal': 6,
        'contractAddress': 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
        'icon': require('@/assets/USDT_TRC20.png')
    },
    {
        'blockName': 'TRON',
        'coinId': 2,
        'coinName': 'USDC_TRC20',
        'decimal': 6,
        'contractAddress': 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8',
        'icon': require('@/assets/USDC.png')
    },
    {
        'blockName': 'Ethereum',
        'coinId': 100,
        'coinName': 'ETH',
        'decimal': 18,
        'contractAddress': '',
        'icon': require('@/assets/ETH.png')
    },
    {
        'blockName': 'Ethereum',
        'coinId': 101,
        'coinName': 'USDT_ERC20',
        'decimal': 6,
        'contractAddress': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        'icon': require('@/assets/USDT_ERC20.png')
    },
    {
        'blockName': 'Ethereum',
        'coinId': 102,
        'coinName': 'USDC_ERC20',
        'decimal': 6,
        'contractAddress': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        'icon': require('@/assets/USDC.png')
    }
]
